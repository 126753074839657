<template>
  <b-col class="col-lg-12">
    <iq-card>
      <b-row class="row w-100">
        <div class="col-lg-8">
          <div class="iq-card iq-card-block iq-card-stretch blog blog-detail" v-for="(subjectList, subject) in Advanceplacement" :key="subject">
         <h4 style="text-transform: capitalize;" class="pl-3 p-0 my-2"><b>{{subject}}</b></h4>
            <div class="iq-card-body ul-list" :href="topic.url" v-for="(topic, index) of subjectList" :key="index">
              <h5 class="pointer">
                <a :href="topic.url">
                  {{topic.subject}}
                </a>
                </h5>
              <div class="blog-description mt-2" >
                <p class="vhtmlTag vueHtmlParent mt-3">
                  {{ topic.discription }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Upcomming events -->
        <div class="col-lg-4 gide_right_mobile_data">
          <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)"/>
          <CareerHomeList v-if="!isMobileDevice"/>
        </div>
      </b-row>
    </iq-card>
  </b-col>
</template>
<script>
import Advanceplacement from "../../../FackApi/json/Advanceplacement.json"
import CareerHomeList from "../ListPages/Career/CareerHomeList.vue"
import EventHomeList from "../Event/EventHomeList.vue"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "AdvancePlacement",
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    socialvue.index()
  },
  components: {
    EventHomeList,
    CareerHomeList
  },
  data () {
    return {
      date: new Date().toDateString(),
      Advanceplacement: Advanceplacement,
      cvCardTitle: "TestPrep",
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList()
    }
  }
}
</script>
<style>
.iq-card-header{
  display:none
}
.ul-list{
  padding: 0px !important;
  line-height: normal !important;
  padding-left: 15px !important;
  list-style: disc;
}
</style>
